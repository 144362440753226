<h2 mat-dialog-title>Notifications</h2>
<div class="row" *ngIf="messages.value.length >= 1;else noNotification">
    <div class="col">
        <div class="card mb-1" *ngFor="let message of messages | async" [ngClass]="getClass(message.state)">
            <div class="card-header">
                {{ message.title }}
            </div>
            <p class="card-body">
                {{ message.text }} - {{ message.createdAt | date: 'hh:MM' }}
            </p>
        </div>
    </div>
</div>

<ng-template #noNotification>
    <div class="card bg-danger text-light">
        <div class="card-header">
            Notifications Empty
        </div>
        <div class="card-body">
            <ul>
                <li>It seems there are no Notification.</li>
            </ul>
        </div>
    </div>
</ng-template>