<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <div class="navbar-brand">Maharaja Shop App</div>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isExpanded = !isExpanded"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" [ngClass]="isExpanded ? '' : 'collapse'">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
       <li 
       *ngFor="let item of menuItems" 
       class="nav-item" 
       [ngClass]="item.children.length > 0 ? 'dropdown' : ''">
        <a
          class="nav-link"
          [routerLink]="item.url" 
          routerLinkActive="active"
          [ngClass]="item.children.length > 0 ? 'dropdown-toggle' : ''"
        >
          {{ item.title }}
        </a>
        <ul *ngIf="item.children.length > 0" class="dropdown-menu">
          <li *ngFor="let child of item.children">
            <a class="dropdown-item" [routerLink]="child.url">
              {{ child.title }}
            </a>
          </li>
        </ul>
       </li>
      </ul>
      <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" (click)="showCalculator()">Calculator</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="showNotification()">Notifications</a>
        </li>
        <li
          class="nav-item dropdown"
          appDropdown
          *ngIf="loggedIn; else signInMenu"
        >
          <a class="nav-link dropdown-toggle">
            {{ displayName }}
          </a>
          <ul class="dropdown-menu">
            <li>
              <div class="dropdown-item">Profile</div>
            </li>
            <li><a (click)="signOut()" class="dropdown-item">Logout</a></li>
          </ul>
        </li>
        <ng-template #signInMenu>
          <li class="nav-item">
            <a
              [routerLink]="['/user', 'sign-in']"
              routerLinkActive="active teal"
              class="nav-link"
            >
              Sign In
            </a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</nav>