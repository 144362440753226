<div class="row">
  <div class="col">
    <table class="table table-sm table-striped table-bordered">
      <thead>
        <tr class="text-center">
          <th>Value</th>
          <th>Count</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let currency of currencyArray | async; let i = index" class="align-middle">
          <td class="d-flex justify-content-between">
            <span class="flex-grow-1 pt-2 text-center">
              {{ currency.denomination | currency: "INR" }}
            </span>
            <button class="btn btn-sm icon-only" (click)="deleteItem(i)">
              <mat-icon>
                delete
              </mat-icon>
            </button>
          </td>
          <td class="text-center">x {{ currency.count }}</td>
          <td class="text-center pr-5">
            {{ currency.amount | currency: "INR" }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="2" class="text-end">Total</th>
          <th class="text-center pr-5">
            {{ currencyTotal | currency: "INR" }}
          </th>
        </tr>
      </tfoot>
    </table>
    <button
      mat-raised-button
      color="warn"
      type="button"
      class="btn m-1 w-100"
      (click)="resetCurrency()"
    >
      Reset
    </button>
  </div>
</div>
