<mat-form-field appearance="outline" class="w-100">
  <mat-label>Equation</mat-label>
  <input
    matInput
    type="text"
    [formControl]="command"
    class="text-center"
    placeholder="Enter Math Equation Here"
  />
  <mat-error *ngIf="command.invalid && (command.dirty || command.touched)">
    Invalid Calculator Command.
  </mat-error>
  <mat-hint>
    {{ previousCalculatorCommand }}
  </mat-hint>
</mat-form-field>
